<template>
 <v-container class="pa-4">
    <v-row class="text-center">
      <v-col cols="12" class="mb-4">
        <v-card>
          <v-card-title class="headline">Поиск по номеру брони</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="numberB"
                  label="Номер брони"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="d-flex align-center">
                <v-btn @click="click()" color="primary">Поиск</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-sheet class="mx-auto" width="400">
          <v-form disabled>
            <v-text-field
              v-model="firstname"
              label="Клиент"
            ></v-text-field>
            <v-text-field
              v-model="carName"
              label="Автомобиль"
            ></v-text-field>
          </v-form>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="headline">Проверка оборудования</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
cols="12"
md="2"
>
<v-checkbox v-model="kabel" label="Кабель ЗУ"></v-checkbox>
</v-col>
<v-col
cols="12"
md="2"
>
<v-checkbox v-model="rezina" label="Зимняя резина"></v-checkbox>
</v-col>
              <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="shina" label="Шины целые"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="kuzov" label="Кузов чистый?"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="salon" label="Салон чистый?"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="bagazh" label="Багажник чистый?"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="video" label="Видеорегистратор"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="kreslo" label="Детское кресло"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="shetka" label="Щетка"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox @change="check()" v-model="zapaska" label="Запасное колесо"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox @change="check1()" v-model="dokatka" label="Докатка"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="ballonn" label="Баллонный ключ"></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
    <v-checkbox v-model="domkrat" label="Домкрат"></v-checkbox>
    </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="headline">Дополнительная информация</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="probeg" label="Пробег"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="benzin" label="Бензина на КМ"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  label="Какие диски?"
                  v-model="diski"
                  :items="['Литые', 'Штампованные']"
                ></v-select>
              </v-col>
              
              <v-col cols="12" md="2">
                <v-file-input label="Договор" v-model="document"></v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn class="mr-2" @click="download()" color="green">Выдача</v-btn>
        <v-btn @click="downloads()" color="blue">Прием</v-btn>
      </v-col>
    </v-row>
    
    <v-dialog v-model="loading" persistent>
      <v-card>
        <v-card-title class="headline">Ожидайте</v-card-title>
        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import axios from "axios";
export default {
  name: 'HelloWorld',

  data: () => ({
    kabel: false,
    rezina: false,
    shina: false,
    kuzov: false,
    salon: false,
    bagazh: false,
    benzin: '',
    probeg: '',
    video: false,
    kreslo: false,
    shetka: false,
    token: "",
    numberB: '',
    car: [],
    firstname: '',
    carName: '',
    zapaska: false,
    dokatka: false,
    ballonn: false,
    domkrat: false,
    osago: "",
    photos: null,
    document: null,
    STS: "",
    diski: "Литые",
    phone: "",
    loading: false,

  }),
  methods: {
    click(){
      let self = this;
      let tokens;
      axios
      .get('https://rentprog.net/api/v1/public/get_token?company_token=3xvybzsxaj49vy2wx8xgg9xu')
      .then(response => {
             
        this.token = response.data.token
        console.log(this.token)


         axios
                    .get('https://rentprog.net/api/v1/public/search_bookings?query=' + this.numberB, {
                          headers: {
                          "Authorization" : `${this.token}`
                        }
                        })
                        .then(r => {
                          this.car.push(r.data[0])
                          this.carName = r.data[0].car_name
                          this.osago = r.data[0].car.body_number
                          this.STS = r.data[0].car.registration_certificate
                          this.firstname = r.data[0].first_name + " " + r.data[0].middle_name + " " + r.data[0].last_name
                          this.phone =r.data[0].client.phone;
                        })
                        .catch(error => {
                        console.log(error)
                        this.carName = "Ничего не найдено"
                        this.firstname = "Ничего не найдено"
                      })
      })
      



      
    },
    check(){
      if(this.zapaska){
        this.dokatka = false
      }
     
    },
    check1(){
      
      if(this.dokatka){
        this.zapaska = false
      }
    },
    download(){
      this.loading = true;
      axios.post('https://admin.euromotorsufa.ru/api/getAPP', {   

                osago: this.osago,
                sts: this.STS,
                kabel: this.kabel,
                rezina: this.rezina,
                shina: this.shina,
                kuzov: this.kuzov,
                salon: this.salon,
                bagazh: this.bagazh,
                benzin: this.benzin,
                probeg: this.probeg,
                video: this.video,
                kreslo: this.kreslo,
                shetka: this.shetka,  
                zapaska: this.zapaska,
                dokatka: this.dokatka,
                ballonn: this.ballonn,
                domkrat: this.domkrat,  
                firstname: this.firstname, 
                phone: this.phone,  
                diski: this.diski,  
                document: this.document,   
                photos: this.photos              
              }, {
 'headers': {
    'Content-Type': 'multipart/form-data'
  }
})
              .then(response => {
                console.log(response.data)
                window.location.href = response.data
              })
              .catch(e => {
              this.errors.push(e)
              })
    },
    downloads(){
      this.loading = true;
      axios.post('https://admin.euromotorsufa.ru/api/getAPPs', {   

                osago: this.osago,
                sts: this.STS,
                kabel: this.kabel,
                rezina: this.rezina,
                shina: this.shina,
                kuzov: this.kuzov,
                salon: this.salon,
                bagazh: this.bagazh,
                benzin: this.benzin,
                probeg: this.probeg,
                video: this.video,
                kreslo: this.kreslo,
                shetka: this.shetka,  
                zapaska: this.zapaska,
                dokatka: this.dokatka,
                ballonn: this.ballonn,
                domkrat: this.domkrat,  
                firstname: this.firstname, 
                phone: this.phone,  
                diski: this.diski,   
                photos: this.photos                
              }, {
 'headers': {
    'Content-Type': 'multipart/form-data'
  }
})
              .then(response => {
              window.location.href = response.data
              })
              .catch(e => {
              this.errors.push(e)
              })
    }
  }
}
</script>
